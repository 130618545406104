import {CHAIN_ID} from "@/util/constants";
import {Address, zeroAddress} from "viem";
import {Position, Token} from "@/components/Perps/types";
import {AddressZero} from "@ethersproject/constants";
import {blast, blastSepolia, goerli, mainnet, sepolia} from "wagmi/chains";

interface ChainData {
  name: string;
  backendUrl: string;
  blockExplorer: string;
  image: string;
  wasabiLongPool: Address;
  wasabiShortPool: Address;
  weth: Address;
  usd: Address;
  swapRouter?: Address;
  balanceChecker?: Address;
  wasabiEth?: Address;
  executionFeeUsd: number;
}

interface ChainConstants {
  [chainId: string]: ChainData;
}

export const chainConstants: ChainConstants = {
  [mainnet.id]: {
    name: "Ethereum",
    image: "/static/ethereum-eth.svg",
    blockExplorer: "https://etherscan.io",
    backendUrl: "https://backend-mainnet-perps.wasabi.xyz",
    wasabiLongPool: "0x8e0edfd6d15f858adbb41677b82ab64797d5afc0",
    wasabiShortPool: "0x0fdc7b5ce282763d5372a44b01db65e14830d8ff",
    weth: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    usd: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", // USDC
    swapRouter: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
    balanceChecker: "0xb1f8e55c7f64d203c1400b9d8555d050f94adf39",
    wasabiEth: "0x630ed8220f9cbc98358a2e2ecb0727d7b9d61397",
    executionFeeUsd: 8,
  },
  [blast.id]: {
    name: "Blast",
    image: "/static/blast.svg",
    blockExplorer: "https://blastscan.io",
    backendUrl: "https://backend-blast-perps.wasabi.xyz",
    wasabiLongPool: "0x046299143A880C4d01a318Bc6C9f2C0A5C1Ed355",
    wasabiShortPool: "0x0301079DaBdC9A2c70b856B2C51ACa02bAc10c3a",
    weth: "0x4300000000000000000000000000000000000004",
    usd: "0x4300000000000000000000000000000000000003",
    swapRouter: "0x337827814155ECBf24D20231fCA4444F530C0555",
    balanceChecker: "0xe668de8d7d6933c47409b2bc14d5d08daefcb0f0",
    wasabiEth: "0x8e2b50413a53f50e2a059142a9be060294961e40",
    executionFeeUsd: 0.5,
  },
  [sepolia.id]: {
    name: "Sepolia",
    image: "/static/sepolia_eth.png",
    blockExplorer: "https://sepolia.etherscan.io",
    backendUrl: "https://backend-sepolia.wasabi.xyz",
    wasabiLongPool: "0xa3975155b728d656f751203e050ec86ee011636e",
    wasabiShortPool: "0x29D47Eb1bc6965F193eC0FaD6d419f7a6Bb49A5C",
    weth: "0x6400C43e5dD1F713fD623d92Dc64831Dd12D3298",
    usd: "0x92ea09e6f1cc933baac19cd6414b64a9d84cc135",
    swapRouter: "0x3bfa4769fb09eefc5a80d6e87c3b9c650f7ae48e",
    balanceChecker: "0x700c018b663348a38e42f1acb8e5a5676dfa0602",
    wasabiEth: "0x885b8f761a72499e697380dc12b194837d44113e",
    executionFeeUsd: 1,
  }
}

export const WASABI_LONG_POOL_ADDRESS: Address = chainConstants[CHAIN_ID.toString()]?.wasabiLongPool || zeroAddress;
export const WASABI_SHORT_POOL_ADDRESS: Address = chainConstants[CHAIN_ID.toString()]?.wasabiShortPool || zeroAddress;
export const WETH_ADDRESS: Address = chainConstants[CHAIN_ID.toString()]?.weth || zeroAddress;
export const USD_ADDRESS: Address = chainConstants[CHAIN_ID.toString()]?.usd || zeroAddress;

export const isUsdToken = (token?: string | undefined): boolean => {
  return token?.toLowerCase() === USD_ADDRESS.toLowerCase();
}

export const isUsdTrade = (position: Position): boolean => {
  return position.side === "LONG" ? isUsdToken(position.collateralCurrencyAddress) : isUsdToken(position.currencyAddress);
}

export const V3_SWAP_ROUTER_ADDRESS = chainConstants[CHAIN_ID.toString()]?.swapRouter || zeroAddress;

export const BALANCE_CHECKER_ADDRESS = chainConstants[CHAIN_ID.toString()]?.balanceChecker || zeroAddress;

export const WASABI_ETH_ADDRESS = chainConstants[CHAIN_ID.toString()]?.wasabiEth || zeroAddress;

export const EXECUTION_FEE_USD = chainConstants[CHAIN_ID.toString()]?.executionFeeUsd || 1;

export const ETH_TOKEN: Token = {
  name: "Ether",
  symbol: "ETH",
  address: AddressZero,
  decimals: 18,
}
export const WETH_TOKEN: Token = {
  name: "Wrapped Ether",
  symbol: "WETH",
  address: WETH_ADDRESS,
  decimals: 18,
}

export const getBackendUrl = (chainId: number): string => {
  return chainConstants[chainId.toString()]?.backendUrl || "";
}

export const getChainName = (chainId: number): string => {
  return chainConstants[chainId.toString()]?.name || "";
}

export const getChainImage = (chainId: number): string => {
  return chainConstants[chainId.toString()]?.image || "";
}

export const getBlockExplorer = (chainId: number = CHAIN_ID): string => {
  return chainConstants[chainId.toString()]?.blockExplorer || "";
}