import { Dialog, Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import React, {Fragment, useState} from "react";
import { Button, Theme } from "react-daisyui";
import {IoIosClose, IoMdClose} from "react-icons/io";

export interface UseModalProps extends React.PropsWithChildren {
  modalVisible: boolean;
  setModalVisible: (show: boolean) => any;
  hide: () => any;
  show: () => any;
  title?: string | React.ReactNode;
  description?: string;
  controlled?: boolean
}

export const useModal = (initialVisibility?: boolean): UseModalProps => {
  const [modalVisible, setModalVisible] = useState(initialVisibility || false);
  const hide = () => setModalVisible(false);
  const show = () => setModalVisible(true);
  return {
    modalVisible, setModalVisible, hide, show
  };
}

export const Modal: React.FunctionComponent<UseModalProps> = ({
  children,
  modalVisible,
  setModalVisible,
  title,
  description,
  controlled
}) => {
  const modalMotion = {
    initial: {
      opacity: 0,
      scale: 0.75,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeOut",
        duration: 0.24,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.75,
      transition: {
        ease: "easeIn",
        duration: 0.15,
      },
    },
  };
  const hide = (e: any) => {
    if (controlled) {
      return;
    }
    setModalVisible(false);
  }
  return (
    <Transition show={modalVisible}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                as={Fragment}>
      <Dialog onClose={hide} as="div"
              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto backdrop-blur-sm rounded-md"
              onClick={hide}>
        <motion.div
          variants={modalMotion}
          initial="initial"
          animate="animate"
          exit="exit"
          className="h-full md:h-max w-full md:py-8 flex items-center justify-center"
          onClick={e => e.stopPropagation()}
        >
          <div className="standard-frame overflow-hidden h-full w-full md:w-max flex items-center justify-center">
            <div className="flex flex-col gap-2 w-full max-w-[1000px] md:min-w-[400px] h-full bg-main-bg">
              {
                (title || !controlled) &&
                <Dialog.Title className="w-full flex flex-row justify-between py-4 px-4 lg:px-6 border-b border-neutral-content/20 text-neutral-content text-lg">
                  <span>{title}</span>
                  {
                    controlled ||
                    <IoMdClose className="text-2xl cursor-pointer hover:scale-110" onClick={hide} />
                  }
                </Dialog.Title>
              }
              {
                description &&
                <Dialog.Description className="text-center text-neutral-content">{description}</Dialog.Description>
              }
              <div className="w-full min-w-[200px] min-h-[200px] flex-grow ">
                {children}
              </div>
            </div>
          </div>
        </motion.div>
      </Dialog>
    </Transition>
  );
};
